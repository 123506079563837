import { Model, Attr, HasOne } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";
import { SurveyRoom } from "./SurveyRoom";
import { SurveySegment } from "./SurveySegment";

@Model()
export class SurveyItem extends ApplicationRecord {
  static jsonapiType = "survey_items";

  @Attr() id!: string;
  @Attr() name!: string;
  // These seem to sometimes come back as a string
  // TODO: confirm what type they actually should be
  @Attr() weight!: number | string;
  @Attr() cube!: number | string;
  @Attr() length!: number;
  @Attr() width!: number;
  @Attr() height!: number;
  @Attr() going!: number;
  @Attr() notGoing!: number;

  @Attr() category!: string;
  @Attr() roomId!: string;
  @Attr() segmentId!: string;
  @Attr() comments?: string;
  @Attr() packing?: number;
  @Attr() notPacking?: number;
  @Attr() definitionId!: string;

  @HasOne() room!: SurveyRoom;
  @HasOne() segment!: SurveySegment;
}
