import { Model, Attr } from "spraypaint";
import { ApplicationRecord } from "./ApplicationRecord";

@Model()
export class SurveySegment extends ApplicationRecord {
  static jsonapiType = "segments";

  @Attr() id!: string;
  @Attr() from!: string;
  @Attr() to!: string;
  @Attr() type!: string;
  @Attr() survey_id!: string;
}
