const envVars = {
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_MOCK_REQUESTS: process.env.NEXT_PUBLIC_MOCK_REQUESTS,
  NEXT_PUBLIC_DISABLE_LOGIN: process.env.NEXT_PUBLIC_DISABLE_LOGIN,
  NEXT_PUBLIC_DEBUGGING: process.env.NEXT_PUBLIC_DEBUGGING,
  NEXT_PUBLIC_DEPOT_HOST: process.env.NEXT_PUBLIC_DEPOT_HOST,
  NEXT_PUBLIC_CUSTOMER_UI_OKTA_DOMAIN:
    process.env.NEXT_PUBLIC_CUSTOMER_UI_OKTA_DOMAIN,
  NEXT_PUBLIC_CUSTOMER_UI_OKTA_CLIENT_ID:
    process.env.NEXT_PUBLIC_CUSTOMER_UI_OKTA_CLIENT_ID,
  NEXT_PUBLIC_CUSTOMER_UI_OKTA_SERVER_ID:
    process.env.NEXT_PUBLIC_CUSTOMER_UI_OKTA_SERVER_ID,
  NEXT_PUBLIC_DATADOG_APPLICATION_ID:
    process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN:
    process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  NEXT_PUBLIC_CHAT_HOST: process.env.NEXT_PUBLIC_CHAT_HOST,
  NEXT_PUBLIC_CHAT_BUS_NO: process.env.NEXT_PUBLIC_CHAT_BUS_NO,
  NEXT_PUBLIC_CHAT_POC: process.env.NEXT_PUBLIC_CHAT_POC,
  NEXT_PUBLIC_CRM_URL: process.env.NEXT_PUBLIC_CRM_URL,
  NEXT_PUBLIC_MUI_PRO_LICENSE_KEY: process.env.NEXT_PUBLIC_MUI_PRO_LICENSE_KEY,
  NEXT_PUBLIC_IDLE_TIMEOUT_MS: process.env.NEXT_PUBLIC_IDLE_TIMEOUT_MS,
  NEXT_PUBLIC_DATADOG_ENV: process.env.NEXT_PUBLIC_DATADOG_ENV,
  DATADOG_SERVICE: process.env.DATADOG_SERVICE,
  APP_VERSION: process.env.APP_VERSION,
};

export type EnvironmentKey = keyof typeof envVars;

export const isProduction = envVars.NODE_ENV?.toLowerCase() === "production";
export const isNotProduction = !isProduction;

/**
 * Get an env value (works in both Cypress and in the app itself)
 * @param key The key of the env value to get
 * @returns the env value
 */
export function getEnv(key: EnvironmentKey): string {
  const value = envVars[key];
  if (value === undefined) {
    if (envVars.NODE_ENV !== "production") {
      console.warn(`Missing env variable ${key}, defaulting to empty string.`);
    }
    return "";
  }
  return value;
}

/**
 * Helper function that returns a boolean indicating whether or not
 * an env value is configured as `enabled`
 * @param key The env key to lookup
 * @returns boolean
 */
export function envEnabled(key: EnvironmentKey) {
  return getEnv(key) === "enabled";
}
