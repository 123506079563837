import { Model, Attr, HasMany } from "spraypaint";
import { Survey } from "./Survey";
import { Service } from "./Service";

@Model()
export class SurveyService extends Service {
  static jsonapiType = "services";
  @Attr() type!: "SURVEY";
  @Attr() subtype!: "VIRTUAL" | "ONSITE";
  @Attr() plannedDatetimeRange!: {
    begin: string;
    end: string;
  } | null;
  @HasMany() surveys!: Survey[];
}
